<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useUserStore from '#/stores/userStore'
import ThemeToggle from '#components/library/action/ThemeToggle.vue'
import BaseHeading from '#/domains/base/components/BaseText/BaseHeading.vue'

const { logout, state } = useUserStore()
const { displaySuccess } = useToast()
const { t } = useI18n()
const handleLogout = () => {
  logout()
  displaySuccess(t('auth.success.logout'))
}
</script>

<template>
  <header
    v-if="state.activeUser"
    class="flex gap-4"
  >
    <BaseAvatar />
    <BaseHeading>Hi, Benjamin</BaseHeading>
    <div class="center-container">
      <ThemeToggle />
    </div>
    <base-language-selector />
    <base-button
      class="btn-logout ml-auto"
      size="sm"
      @click="handleLogout"
    >
      Logout
    </base-button>
  </header>
</template>
