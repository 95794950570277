<script setup lang="ts">
import { useBreakpoints } from '@vueuse/core'
import { onBeforeUnmount } from 'vue'
import { useAppStore } from '#/stores/appStore'
import AppNavigation from '#/domains/app/components/navigations/AppNavigation.vue'

const { updateState, state } = useAppStore()

const breakpoints = useBreakpoints({
  'tab-port-small': 600,
  'phone': 400,
})

const closeMenu = (e: Event) => {
  if (!e.target?.closest('aside')) {
    updateState('collapsedAppMenu', false)
  }
}

watch(
  () => breakpoints.phone,
  ({ value }) => {
    if (value) {
      window.addEventListener('click', closeMenu)
    }
  },
  { immediate: true },
)

onBeforeUnmount(() => {
  window.removeEventListener('click', closeMenu)
})
</script>

<template>
  <aside
    class="shadow shadow-blue-300 dark:shadow-primary-950"
    :class="{ collapsed: state.collapsedAppMenu }"
  >
    <AppNavigation :icon-only="state.collapsedAppMenu" />

    <!--    <BaseIcon -->
    <!--      tag="button" -->
    <!--      class="btn-chevron" -->
    <!--      :class="{ 'btn-chevron&#45;&#45;flip': state.collapsedAppMenu }" -->
    <!--      color="none" -->
    <!--      icon="chevron-right" -->
    <!--      size="small" -->
    <!--      @click="updateState('collapsedAppMenu', !state.collapsedAppMenu)" -->
    <!--    /> -->
  </aside>
</template>
