<script setup lang="ts">
import { version } from 'vue'
import protectedSite from '#config/protectedSite'

// ************* TYPES ************* //
interface Props {
  iconOnly?: boolean
}

// ************* PROPS ************* //
defineProps<Props>()

const route = useRoute()

const protectedRoutes = computed(() =>
  protectedSite.getRoutes(route.name as string),
)
</script>

<template>
  <nav class="app-nav">
    <div class="mb-4 flex items-center justify-between">
      <nuxt-link to="/">
        <base-logo />
      </nuxt-link>
      <transition name="fade">
        <span
          v-if="!iconOnly"
          class="app-nav__version-number"
        >v {{ version }}
        </span>
      </transition>
    </div>
    <div class="mb-4">
      <transition name="fade">
        <h3
          v-if="!iconOnly"
          class="nav-heading"
        >
          Tools
        </h3>
      </transition>

      <ul class="links">
        <li
          v-for="r in protectedRoutes"
          :key="r.id"
          class="links__item"
        >
          <nuxt-link
            class="link"
            :class="{ 'link--center': iconOnly }"
            :to="r.to"
          >
            <base-icon :icon="r.icon" />
            <transition name="fade">
              <span
                v-show="!iconOnly"
                class="hidden"
              >
                {{ $t(r.translation) }}
              </span>
            </transition>
          </nuxt-link>
        </li>
      </ul>
    </div>

    <transition name="fade">
      <h3
        v-if="!iconOnly"
        class="nav-heading hidden"
      >
        Creators
      </h3>
    </transition>

    <transition name="fade">
      <h3
        v-if="!iconOnly"
        class="nav-heading mb-auto hidden"
      >
        Messages
      </h3>
    </transition>

    <ul class="settings-list">
      <li class="links__item">
        <nuxt-link
          class="link"
          :class="{ 'link--center': iconOnly }"
          :to="{ name: 'app-index-settings' }"
        >
          <!--          <base-icon :icon="route.name === 'app-index-settings'? 'cog-8-tooth-solid&';: &';cog-8-tooth&'" /> -->
          <transition name="fade">
            <span
              v-show="!iconOnly"
              class="hidden"
            >
              {{ $t('Settings') }}
            </span>
          </transition>
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped></style>
