import { v4 as createUid } from 'uuid'

export default {
  getRoutes: (routeName: string) => [
    {
      to: {
        name: 'app-index-dashboard',
      },
      translation: 'app.nav.dashboard',
      icon: routeName === 'app-index-dashboard' ? 'home-solid' : 'home-reg',
      id: createUid(),
    },
    {
      to: {
        name: 'app-index-tools-QrCodeGenerator',
      },
      translation: 'app.nav.qrGenerator',
      icon:
        routeName === 'app-index-tools-QrCodeGenerator'
          ? 'qr-code'
          : 'qr-code-mini',
      id: createUid(),
    },
    {
      to: {
        name: 'app-index-tools-GalaxyGenerator',
      },
      translation: 'app.nav.galaxyGenerator',
      icon:
        routeName === 'app-index-tools-GalaxyGenerator'
          ? 'globe-asia-australia-solid'
          : 'globe-asia-australia',
      id: createUid(),
    },
  ],
}
